<template>
  <!-- <div class="columns no-margin"> -->
  <div class="columns no-margin">
    <div class="column no-padding is-full">
      <div class="card">
        <header class="card-header has-background-primary">
          <p class="card-header-title has-text-light">
            {{ headerText }} JADWAL
          </p>
        </header>
        <div class="card-content events-card">
          <!-- perlu diganti dengan div spacing -->
          <b-field class="field-spacer"></b-field>
          <template v-if="errorMap.non_field_errors">
            <p class="help is-danger">{{ errorMap.non_field_errors }}</p>
            <b-field class="field-spacer"></b-field>
          </template>

          <b-field
            label="Departemen"
            custom-class="is-small"
            :type="{ 'is-danger': errorMap.sub_departemen }"
            :message="errorMap.sub_departemen"
          >
            <generic-select
              v-model="jadwal.sub_departemen"
              apiPath="/programstudi/subdepartemen/"
              @input="validateInput('sub_departemen')"
            ></generic-select>
          </b-field>

          <b-field
            class="file"
            :type="{ 'is-danger': errorMap.url }"
            :message="errorMap.url"
          >
            <b-upload
              v-model="fotoMdl"
              @input="resize"
              accept=".jpg, .jpeg, .png"
            >
              <a class="button is-primary">
                <b-icon icon="upload"></b-icon>
                <span>Upload Jadwal</span>
              </a>
            </b-upload>
            <span class="file-name" v-if="fotoMdl">{{ fotoMdl.name }}</span>
          </b-field>

          <b-field
            label="Catatan"
            custom-class="is-small notrequired"
            :type="{ 'is-danger': errorMap.catatan }"
            :message="errorMap.catatan"
          >
            <b-input
              v-model="jadwal.catatan"
              type="textarea"
              @input="validateInput('catatan')"
            ></b-input>
          </b-field>

          <!-- perlu diganti dengan div spacing -->
          <b-field class="field-spacer"></b-field>

          <div class="buttons">
            <b-button
              class="is-primary has-text-weight-semibold"
              @click.stop="cancel"
              >Batal</b-button
            >
            <b-button
              class="is-primary has-text-weight-semibold"
              :disabled="!isValid"
              @click.stop.prevent="saveObject(false)"
              >Simpan</b-button
            >
          </div>
          <b-loading :is-full-page="false" :active.sync="loading"></b-loading>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import createUpdateMixin from "@/apps/core/mixins/createUpdateMixin";
import Stase from "../models/jadwalCU.js";
import UploadPhoto from "@/apps/core/modules/uploadPhoto.js";

export default {
  name: "StaseCreateUpdate",
  props: ["headerText"],
  mixins: [createUpdateMixin],
  components: {
    GenericSelect: () =>
      import("@/apps/core/components/GenericSelect.vue")
  },
  data() {
    this.jadwalMdl = new Stase();
    this.objectMdl = this.jadwalMdl; // alias yg digunakan di cuMixin
    let obv = this.jadwalMdl.getObservables();
    obv.foto = null;
    obv.fotoMdl = null;
    this.uploadPhoto = new UploadPhoto("jadwaldepartemen");
    return obv;
  },
  methods: {
    fetchData() {
      this.uploadPhoto.requestAWS();
    },
    resize(foto) {
      this.uploadPhoto.resize(foto, (foto, url) => {
        this.foto = foto;
        this.jadwal.url = url;
        this.validateInput("url");
      });
    },
    upload() {
      this.uploadPhoto.upload(this.foto, this.saveObject, () => {
        this.jadwal.url = null;
        this.foto = null;
        this.fotoMdl = null;
      });
    },
    saveObject(commit) {
      this.objectMdl.setCommit(commit);
      if (commit) {
        this.objectMdl.save(this.saveContext, () => {
          this.$router.go(-1);
        });
      } else {
        this.objectMdl.save(this.saveContext, this.upload);
      }
    },
  },
  watch: {
    jadwal: {
      // jadwal berubah, update edited
      handler(newValue, oldValue) {
        if (!this.jadwalMdl.getEdited()) {
          this.setEdited(this.isCreate || !!oldValue.id);
        }
      },
      deep: true,
    },
  },
};
</script>
